import { Currency } from "@graphql/types";
import { getCurrencyFormat } from "./getCurrencyFormat";
import { getCurrency } from "./graphql";

export const getCurrencyConversion = (
  amount: number,
  currency: Pick<Currency, "id" | "quotes">,
  forceCurrencyId?: string,
  locale = "en-US"
) => {
  if (forceCurrencyId) {
    return getCurrencyFormat(amount, forceCurrencyId, locale);
  }

  const currencyId = getCurrency();

  // same currency, we don't need to convert it.
  if (currencyId === currency.id) {
    return getCurrencyFormat(amount, currencyId, locale);
  }

  const currencyRate = currency.quotes.find(
    (item) => item.currency === currencyId
  );

  // we couldn't match the currency selected
  if (!currencyRate) {
    return getCurrencyFormat(amount, currencyId, locale);
  }

  const convertedAmount = Number((amount * currencyRate.rate).toFixed());

  return getCurrencyFormat(convertedAmount, currencyId, locale);
};

export const getCurrencyConversionAmount = (
  amount: number,
  currency: Pick<Currency, "id" | "quotes">
) => {
  const currencyId = getCurrency();

  // same currency, we don't need to convert it.
  if (currencyId === currency.id) {
    return amount;
  }

  const currencyRate = currency.quotes.find(
    (item) => item.currency === currencyId
  );

  // we couldn't match the currency selected
  if (!currencyRate) {
    return amount;
  }
  const convertedAmount = Number((amount * currencyRate.rate).toFixed());
  return convertedAmount;
};

import { t } from "@lingui/macro";
import { Duration } from "luxon";

export const getDurationFromHourMin = (hhMmFormat: string) => {
  const duration = hhMmFormat.split(":");
  if (duration.length === 2) {
    const hours = Number(duration[0]);
    const min = Number(duration[1]);

    if (min <= 0) {
      if (hours === 1) {
        return t({
          id: "duration.hour",
          message: `${hours} hour`,
        });
      }
      return t({
        id: "duration.hours",
        message: `${hours} hours`,
      });
    }

    //Hours in singular
    if (hours === 1) {
      return t({
        id: "duration.hour-min-",
        message: `${hours} hour ${min} min`,
      });
    }

    //Hours in plural
    return t({
      id: "duration.hours-min",
      message: `${hours} hours ${min} min`,
    });
  }
  return hhMmFormat;
};

export const getDuration = (ISODuration: string) => {
  const { hours = 0, minutes = 0, days } = Duration.fromISO(
    ISODuration
  ).toObject();

  const min = minutes;

  if (
    (!hours || hours === 0) &&
    (!minutes || min === 0) &&
    (!days || days === 0)
  ) {
    return "--";
  }

  if (days && days > 0) {
    return t({
      id: "duration.days",
      message: `${days} days`,
    });
  }

  if (minutes <= 0) {
    if (hours === 1) {
      return t({
        id: "duration.hour",
        message: `${hours} hour`,
      });
    }
    return t({
      id: "duration.hours",
      message: `${hours} hours`,
    });
  }

  //Hours in singular
  if (hours === 1) {
    return t({
      id: "duration.hour-min-",
      message: `${hours} hour ${min} min`,
    });
  }

  //Hours in plural
  return t({
    id: "duration.hours-min",
    message: `${hours} hours ${min} min`,
  });
};
